<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-6">
                <h4>
                  <router-link :to="{name:'dashboard'}">
                    <i class="fas fa-arrow-left"></i>
                  </router-link>
                  Manage faculty members
                </h4>
              </div>
              <!--                        <div class="col-2 text-left">-->
              <!--                            <router-link :to="{name:'dashboard'}" class="btn btn-primary">-->
              <!--                                <i class="fas fa-arrow-left"></i>-->
              <!--                                Back-->
              <!--                            </router-link>-->
              <!--                        </div>-->
              <!--                        <div class="col-4">-->
              <!--                            <h4>Manage Faculty Members</h4>-->

              <!--                        </div>-->
              <div class="col-12">
                <table class="table table-stripe">
                  <thead>
                  <th>Program</th>
                  <th>Total</th>
                  <th>Action</th>
                  </thead>
                  <tbody>
                  <tr v-for="(program, index) of programs" :key="index">
                    <td>{{ program.title }}</td>
                    <td>{{ program.teacher_count }}</td>
                    <td>
                        <span @click="openDialog(program.id)">
                          <i class="fas fa-eye"></i>
                        </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <b-pagination
                      @input="getUsers"
                      v-model="page"
                      :total-rows="total"
                      :per-page="perPage"
                      first-number
                      last-number
                  ></b-pagination>
                  <!--  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <teacher ref="teacher" :program_id="program_id" @close_dialog="closeDialog"></teacher>
    </div>
  </v-app>
</template>
<script>
import ProgramService from "@/core/services/level/program/ProgramService";
import Teacher from "./CreateOrUpdate";

const programService = new ProgramService();

export default {
  components: {
    Teacher
  },
  data() {
    return {
      programs: [],
      program_id: null,
      page: 1,
      total: 0,
      perPage: 25,
    };
  },
  mounted() {
    this.getPrograms();
  },
  methods: {
    getPrograms() {
      programService.paginate().then(response => {
        this.programs = response.data.data;
        this.page = parseInt(response.data.meta.current_page);
        this.total = parseInt(response.data.meta.total);
        this.perPage = parseInt(response.data.meta.per_page);
      });
    },
    openDialog(id) {
      this.program_id = id;
      this.$refs["teacher"].showModal();
    },
    closeDialog(id) {
      this.program_id = id;
      this.$refs["teacher"].hideModal();
      this.getPrograms();
    }
  }
};
</script>
