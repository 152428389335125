<template>
  <div>
    <b-modal
      id="modal-scrollable"
      size="xl"
      ref="my-modal"
      hide-footer
      title="Teacher List"
    >
      <div class="d-block text-center">
        <div class="row" v-if="users.length>0">
          <div class="col-md-3 col-sm-12 mt-4" v-for="(user, index) of users" :key="index">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-3">
                    <input
                      type="checkbox"
                      class="form-control float-left"
                      v-model="teacher"
                      :value="user.id"
                    />
                  </div>

                  <div class="col-9">
                    <span class="font-weight-bold">{{`${user.first_name} ${user.last_name}`}}</span>
                    <br />
                    <small class="text-lowercase">{{`${user.email}`}}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <pre>{{teacher}}</pre> -->
      </div>
      <div class="row">
        <div class="col-6">
          <b-button size="sm" class="mt-3" variant="danger" block @click="hideModal">Cancel</b-button>
        </div>
        <div class="col-6">
          <b-button  v-if="checkIsAccessible('user', 'create')" size="sm" class="mt-2" variant="success" block @click="saveTeacher">Save</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import UserService from "@/core/services/user/UserService";
import TeacherService from "@/core/services/level/program/teacher/TeacherService";

const userService = new UserService();
const teacherService = new TeacherService();

export default {
  props: ["program_id"],
  data() {
    return {
      users: [], // Must be an array reference!
      teacher: []
    };
  },
  mounted() {
    this.getTeachers();
  },
  methods: {
    getTeachers() {
      userService.getTeachers().then(response => {
        this.users = response.data;
      });
    },
    showModal() {
      this.getTeachers();
      this.getTeacherList();
      this.$refs["my-modal"].show();
    },
    hideModal() {
          this.teacher = []
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    getTeacherList() {
      teacherService.all(this.program_id).then(response => {
        let teacher = response.data.data;
        if (teacher.length > 0) {
          teacher.forEach(ele => {
            this.teacher.push(ele.user_id);
          });
        }
      });
    },
    saveTeacher() {
      teacherService
        .store(this.program_id, { teachers_id: this.teacher })
        .then(response => {
          this.$snotify.success("Information added");
          this.$emit("close_dialog");
        }).error((error)=>{
          // console.log(error);
      });
    }
  }
};
</script>
